import React from "react"

import Base from "../base/Base"
import Tile from "../../../ui/atom/tile/Tile"

import * as Styles from "./Simple.module.scss"


const Simple = ({
	title,
    children,
	...props
}) =>
	<Base title={ title }>
		<section className={ Styles.section }>
			<Tile
				className={ Styles.content }
				{ ...props }
			>
				{ children }
			</Tile>
		</section>
	</Base>

export default Simple