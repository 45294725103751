import React from "react";
import Simple from "../components/layout/page/simple/Simple"


const Imprint = () =>
    <Simple
        title={ "Impressum" }
        style={ {
                maxWidth: "60rem",
                margin: "0 auto"
        } }
    >
        <h2>Impressum</h2>

        <h3>Angaben gemäß § 5 TMG:</h3>
        <p>
            Joseph Ribbe<br/>
            Heinrich-Mann-Allee 23<br/>
            14473 Potsdam
        </p>

        <h4>Kontakt:</h4>
        <p>
            Telefon: +49 174 / 66 20 40 5<br/>
            E-Mail: joseph@karmalokal.de
        </p>
    </Simple>

export default Imprint;